import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/Layout'
import LeftWithImage from '../../components/Global/Hero/LeftWithImage'
import { YellowButton } from '../../components/Global/Button'
import SEO from '../../components/Global/SEO'
import SectionTitle from '../../components/Global/SectionTitle'
import { ContentWrapper, InfoWithImageRight, InfoWithImageLeft, SVGPattern } from '../../components/Global/Content'
import SimpleCenteredTestimonial from '../../components/Global/Testimonial/SimpleCentered'

const CruiseExcursionsPage = ({ location }) => {
  const { seoImg, cruise, shoreexcursions, giantscauseway, mark } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "luxury-cruise-ship-excursions.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
      cruise: file(relativePath: { eq: "luxury-cruise-ship-excursions.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900, maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      shoreexcursions: file(relativePath: { eq: "cruise-ship-shore-excursions.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 980, maxHeight: 1140, cropFocus: WEST) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      giantscauseway: file(relativePath: { eq: "giants-causeway-shore-excursion.jpeg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 980, maxHeight: 1140) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mark: file(relativePath: { eq: "mark-wiley.jpeg" }) {
        childImageSharp {
          fluid(quality: 70, maxHeight: 40, maxWidth: 40) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>

      <SEO
        title="Luxury Cruise Ship Excursions | Private Tours of Ireland, Belfast, Dublin"
        description="Discover any of Ireland’s major tourist attractions in style and comfort with our fantastic range of luxury cars. We look after you from the moment you dock and return you to the ship for your departure."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <LeftWithImage
        image={cruise.childImageSharp.fluid}
        imageAlt="Luxury Cruise Ship Excursions"
      >
        <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Services
        </div>
        <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
          Luxury Cruise Ship
          <br className="hidden md:inline" />
          <span className="text-yellow-300">Shore Excursions</span>
        </h2>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          Discover any of Ireland’s major tourist attractions in style and comfort with our fantastic range of luxury cars. We look after you from the moment you dock and return you to the ship for your departure.
        </p>
        <YellowButton to="/book-now" className="inline-block mt-8">Book Now</YellowButton>
      </LeftWithImage>

      <ContentWrapper>
        <SectionTitle
          title="Shore Excursions to Enjoy"
          text="At Chauffeur Me we really do go that extra mile to ensure that you have a fabulous day out with us. It’s your day, and your time, therefore each tour will be carefully crafted to create a truly memorable day."
        />
        <InfoWithImageRight
          image={shoreexcursions.childImageSharp.fluid}
          imageAlt="Luxury cruise excursions from Belfast Docks with Chauffeur Me." 
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Chauffeured Tours of Ireland
          </h4>
          <p className="mt-3 text-lg leading-7 text-gray-500">
            Chauffeur Me provide individual and group tours around Ireland for visiting tourists arriving on cruise ships docking in Belfast.
          </p>

          <ul className="mt-10">
            <li>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7L20 7M20 7L16 3M20 7L16 11M16 17L4 17M4 17L8 21M4 17L8 13" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Return Travel</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    We'll collect you from the cruise ship in Belfast docks at your preferred start time and return you in time for the ship's departure.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"/>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Personal Chauffeur</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    With a personal chauffeur-driven car, you design the tour to suit your priorities. Your chauffeur is also your guide for the day and will be happy to answer questions about life in Northern Ireland.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Luxury Vehicles</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Chauffeur Me only use to of the range executive vehicles with air conditioning and luxury seating. Our luxury fleet of vehicles can cater for up to 7 people.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </InfoWithImageRight>

        <SVGPattern />

        <InfoWithImageLeft
          image={giantscauseway.childImageSharp.fluid}
          imageAlt="Visit the Giant's Causeway with Chauffeur Me."
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Discover the Best of Ireland
          </h4>
          <p className="mt-3 text-lg leading-7 text-gray-500">
            Northern Ireland is a small place with many attractions outside Belfast that can be seen in a day.
          </p>

          <ul className="mt-10">
            <li>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Private Tours</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    We have a range of tours available which can be tailored to suit your needs. Visit the famous Giants Causeway, Bushmills Whiskey Distillery or The Dark Hedges made famous by Game of Thrones.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Bespoke Tours</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Got somewhere you want to see? No problem. You design the tour to suit your priorities and we'll take you there with our personal chauffeur-driven car.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </InfoWithImageLeft>
      </ContentWrapper>

      <SimpleCenteredTestimonial
        content="Our driver, Derek, was brillaint with our small group for our visit to Ireland. He was knowledgeable, friendly and so much fun. He took us to a few requested locations that were not part of original tour packages and we were delighted with every minute."
        author="Mark Wiley"
        position="Cruise Ship Shore Excursion"
        image={mark.childImageSharp.fluid}
      />
      
    </Layout>
  )
}

export default CruiseExcursionsPage